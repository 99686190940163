import React, { useState, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import Viewport from "./Viewport";
import NavBar from "./NavBar";

import placeholder from "./resources/buttons/placeholder.png";

import "./style/App.scss";
import "./style/Nav.scss";
import "./style/Text.scss";
import "./style/Viewport.scss";
import "./style/Layout.scss";
import "./style/Language.scss";
import "./style/Animation.scss";

function LandingPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [showViewport, setShowViewport] = useState(
    ["about", "contact", "areas"].includes(searchParams.get("direction")) ? true : false
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentDirection, setCurrentDirection] = useState(
    searchParams.get("direction") || "home"
  );

  const preloadImages = (images) => {
    images.forEach((image) => {
      new Image().src = image;
    });
  };
  
  // Call preloadImages before rendering the component
  const bgImages = useMemo(
    () => [
      process.env.PUBLIC_URL + "/imgSP.jpg",
      process.env.PUBLIC_URL + "/imgRJ.jpg",
    ],
    []
  );
  preloadImages(bgImages);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
    }, 4250);

    return () => {
      clearInterval(interval);
    };
  }, [bgImages]);

  const backgroundImageStyle = {
    backgroundImage: `url(${bgImages[currentImageIndex]})`,
  };

  var cityName = currentImageIndex === 1 ? "Rio de Janeiro" : "São Paulo";

  return (
    <div className="App">
      <div className="background-image" style={backgroundImageStyle}>
        <div className="container">
          {NavBar(
            currentImageIndex,
            currentDirection,
            setCurrentDirection,
            setShowViewport
          )}
          <div className="locationBar">
            <div className="newLocation">
            
              <p className="locationTitle">{cityName}</p>
            </div>
          </div>
          
        </div>
        <div className="view-container">
            {Viewport(showViewport, currentDirection)}
          </div>
      </div>
    </div>
  );
}

export default LandingPage;
