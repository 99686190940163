import { useState } from "react";

function Areas() {
    const [statusContention, setStatusContention] = useState(false);
    const [statusFinancial, setStatusFinancial] = useState(false);
    const [statusPayments, setStatusPayments] = useState(false);
  
    const showCompliance = () => {
      setStatusContention(!statusContention); //
    };
  
    const showFinancial = () => {
      setStatusFinancial(!statusFinancial); //
    };
  
    const showPayments = () => {
      setStatusPayments(!statusPayments); //
    };
  
    return (
      <section id="areas-atuacao" className="pt">
        <div className="container-inner">
          <div className="titulo-wrapper">
            <h2 className="title lang-pt">Áreas de atuação</h2>
            <h2 className="title lang-en">Practices</h2>
          </div>
          <div className="section-content">
            <div className="faq-wrapper">
              <div className="item">
                <a className="item-header" href="#" onClick={showCompliance}>
                  <h2 className="lang-pt">Compliance</h2>
                  <h2 className="lang-en">Compliance</h2>
                  <div
                    className={`close-icon ${statusContention ? "active" : ""}`}
                    x-show="isOpen"
                  >
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L8 8M15 15L8 8M8 8L1 15M8 8L15 1"
                        strokeWidth={2}
                      />
                    </svg>
                  </div>
                </a>
                <div
                  x-show="isOpen"
                  className="faq-answer lang-pt"
                  id={`${statusContention ? "active" : ""}`}
                >
Um escritório de advocacia especializado em compliance, como é o caso do Ferolla Advogados, desempenha um papel crucial para as empresas ao ajudá-las a estabelecer e manter práticas comerciais éticas e em conformidade com as leis e regulamentações aplicáveis ao setor que atuam. O objetivo central do compliance é prevenir violações legais, minimizar riscos e garantir que a empresa opere de maneira transparente e responsável, ajudando a minimizar riscos e proteger a reputação e os interesses da empresa e de seus stakeholders. Possuímos larga experiência nesse trabalho para pessoas jurídicas, destacando-se como principais atividades:
<br/>
<h3>Implementação de Programas de Compliance:</h3> O escritório auxilia a empresa na criação e implementação de programas de compliance personalizados, que incluem políticas, procedimentos e diretrizes para garantir que todas as atividades da empresa estejam em conformidade com leis, regulamentações e padrões éticos relevantes.
<br/>
<h3>Treinamentos e Educação:</h3> Oferecemos treinamentos regulares para funcionários e gestores sobre as políticas de compliance, as implicações legais de suas ações e a importância de seguir as práticas éticas.
<br/>
<h3>Canal de Denúncias:</h3> O escritório implementa – em caso de inexistência – ou aprimora o funcionamento do canal de denúncias da empresa, que é uma ferramenta essencial para fortalecer a cultura de compliance de uma organização. Ele capacita a empresa a identificar, lidar e prevenir potenciais violações, protegendo sua reputação, mitigando riscos e promovendo um ambiente de integridade e ética.
<br/>
<h3>Avaliação de Riscos:</h3> O escritório identifica e avalia os riscos legais e regulatórios enfrentados pela empresa, auxiliando na priorização e mitigação desses riscos por meio de estratégias apropriadas.
<br/>
<h3>Auditorias Internas:</h3> São conduzidas auditorias periódicas para revisar processos internos e garantir que todas as operações estejam em conformidade com os padrões estabelecidos.
<br/>
<h3>Aconselhamento Jurídico:</h3> A equipe oferece orientação legal sobre questões específicas relacionadas ao cumprimento das leis e regulamentações aplicáveis.
<br/>
<h3>Códigos de Conduta e Ética:</h3> O escritório auxilia na criação e revisão de códigos de conduta e ética, que delineiam os princípios e valores que guiam as ações e decisões dos funcionários.
<br/>
<h3>Investigações Internas:</h3> Quando ocorrem alegações de má conduta, fraude ou violações, o escritório conduz investigações internas para apurar os fatos e determinar a adequação das ações tomadas.
<br/>
<h3>Relatórios e Comunicação:</h3> São elaborados relatórios regulares de compliance para a alta administração e para as autoridades reguladoras, quando necessário.
<br/>
<h3>Gerenciamento de Conformidade:</h3> O escritório auxilia no acompanhamento constante das mudanças legais e regulatórias, garantindo que a empresa permaneça atualizada e adaptada a essas mudanças.
<br/>
<h3>Mitigação de Multas e Sanções:</h3> Em casos de infrações, o escritório trabalha para mitigar as consequências legais, incluindo multas e sanções.
<br/><br/>
                </div>
                <div
                  x-show="isOpen"
                  className="faq-answer lang-en"
                  id={`${statusContention ? "active" : ""}`}
                >

                </div>
              </div>
              <div className="item">
                <a className="item-header" href="#" onClick={showFinancial}>
                  <h2 className="lang-pt">DIREITO PENAL EMPRESARIAL</h2>
                  <h2 className="lang-en">CORPORATE CRIMINAL LAW</h2>
                  <div
                    className={`close-icon ${statusFinancial ? "active" : ""}`}
                    x-show="isOpen"
                  >
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L8 8M15 15L8 8M8 8L1 15M8 8L15 1"
                        strokeWidth={2}
                      />
                    </svg>
                  </div>
                </a>
                <div
                  x-show="isOpen"
                  className="faq-answer lang-pt"
                  id={`${statusFinancial ? "active" : ""}`}
                >
                  O setor de Direito Penal Empresarial do Ferolla Advogados concentra-se em oferecer assistência legal a empresas que enfrentam questões criminais relacionadas às suas atividades comerciais. Possuir um escritório de advocacia especializado em Direito Penal Empresarial é crucial para ajudar as empresas a navegar pelo complexo cenário legal e regulatório em casos de supostas infrações criminais, pois ele ajudará a proteger os direitos da empresa e de seus representantes legais, ao mesmo tempo em que minimizará as consequências legais e de reputação associadas a questões criminais. Nesse contexto é que atendemos a departamento jurídicos de diversas empresas que acabam se deparando com a necessidade de possuírem a assessoria ininterrupta de um escritório criminal em razão de eventuais incidentes envolvendo a operação.
                  Aqui estão algumas das atividades que o nosso escritório poderá desempenhar para nesse segmento da atuação:
                  <br/>
                  <h3>Defesa:</h3>O escritório defende os interesses de empresas e de seus executivos ou colaboradores envolvidos em investigações e processos criminais. Isso pode envolver acusações de fraude, corrupção, lavagem de dinheiro, evasão fiscal, concorrência desleal, entre outros crimes econômicos.

                  <h3>Representação em Processos Criminais:</h3> O escritório atua em nome da empresa ou de seus representantes legais em audiências judiciais, garantindo que seus direitos sejam protegidos e que as alegações sejam adequadamente contestadas.

                  <h3>Investigações Internas:</h3> O escritório pode conduzir investigações internas para avaliar a extensão de possíveis irregularidades e ajudar a empresa a tomar decisões informadas sobre a melhor estratégia a ser adotada.

                  <h3>Assessoria na Prevenção:</h3> Além de lidar com casos reativos, o escritório também pode oferecer orientação sobre como prevenir a ocorrência de crimes empresariais, implementando políticas e procedimentos que estejam em conformidade com as leis e regulamentações.

                  <h3>Acordos de Não Persecução Penal (ANPP):</h3> Em alguns casos, o escritório pode negociar acordos com as autoridades para evitar processos judiciais completos, resultando em penas mais leves ou outras medidas alternativas.

                  <h3>Recuperação de Ativos:</h3> Em casos de fraude ou corrupção, o escritório pode trabalhar para recuperar ativos perdidos pela empresa e buscar indenizações.

                  <h3>Conformidade Regulatória:</h3> O escritório oferece orientação sobre como a empresa pode cumprir as regulamentações e leis aplicáveis para evitar problemas legais no futuro.

                  <h3>Treinamento e Educação:</h3> O escritório pode oferecer treinamento para funcionários e gestores sobre as implicações legais das ações comerciais e como evitar atividades que possam levar a consequências criminais.

                  <h3>Consultoria Estratégica:</h3> O escritório fornece aconselhamento estratégico sobre como lidar com situações complexas e delicadas para minimizar danos legais e de reputação.

                  <h3>Defesa dos Interesses da Empresa:</h3> O escritório trabalha para proteger os interesses da empresa, sua reputação e sua continuidade operacional.
                  <br/><br/>

                </div>
                <div
                  x-show="isOpen"
                  className="faq-answer lang-en"
                  id={`${statusFinancial ? "active" : ""}`}
                >
                  Our professionals combine extensive experience in the market,
                  high technical and academic qualifications, and deep knowledge
                  of our client's industries. We offer solutions for national and
                  multinational companies that operate in various sectors of the
                  economy, such as technology, energy and infrastructure,
                  services, industry, retail, agribusiness, and finance.​ <br />
                  <br />
                  ​The practice is subdivided into tax advisory – which involves,
                  among others, (i) the preparation of briefs, (ii) legal
                  opinions, (iii) tax planning, (iv) M&amp;As and other
                  transactions, and (v) due diligences – and tax litigation –
                  which encompasses, among other things (i) the administrative and
                  judicial defense of tax assessments, (ii) representation in tax
                  enforcement actions, (iii) advice in other collection measures,
                  (iv) recovery of tax credits, and (v) development of new tax
                  recovery thesis. In each of these cases, we have professionals
                  with expertise in municipal, state and federal taxation.
                </div>
              </div>
              <div className="item">
                <a className="item-header" href="#" onClick={showPayments}>
                  <h2 className="lang-pt">
                    Payments, Banking, Fintech &amp; Crypto
                  </h2>
                  <h2 className="lang-en">
                    Payments, Banking, Fintech &amp; Crypto
                  </h2>
                  <div className={`close-icon ${statusPayments ? "active" : ""}`}>
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L8 8M15 15L8 8M8 8L1 15M8 8L15 1"
                        strokeWidth={2}
                      />
                    </svg>
                  </div>
                </a>
                <div
                  x-show="isOpen"
                  className="faq-answer lang-pt"
                  id={`${statusPayments ? "active" : ""}`}
                >
                Os avanços tecnológicos ao longo da última década estimularam uma mudança radical na forma como os serviços financeiros são prestados e regulamentados. O Ferolla Advogados tem assessorado inúmeros players desse segmento, desde instituições financeiras e instituições de pagamentos a desenvolvedores de soluções tecnológicas para o mercado financeiro na blockchain e exchanges de criptomoedas. <br/><br/>
Aconselhamos, assim, empresas na intersecção entre finanças e tecnologia sobre os desafios únicos que enfrentam, principalmente os aspectos regulatórios, que precisam ser acompanhados de perto devido às reiteradas alterações que vêm ocorrendo.
Os nossos parceiros permitem uma atuação multidisciplinar do escritório no segmento PAYMENTS, FINTECHS, BANKING, BLOCKCHAIN & CRYPTOCURRENCIES, com advogados aconselhando regularmente sobre questões de serviços financeiros ao consumidor, criação de empresas, financiamento de capital de risco e de capital privado, fusões e aquisições, ofertas públicas, questões regulatórias, proteção de propriedade intelectual, privacidade e segurança de dados, direito do trabalho e advocacia contenciosa.
                </div>
                <div
                  x-show="isOpen"
                  className="faq-answer lang-en"
                  id={`${statusPayments ? "active" : ""}`}
                >

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  export default Areas;