import ReactDOM from 'react-dom/client';
import React, { useState, useEffect } from 'react';

//import Routing Provider;
import {RouterProvider} from "react-router";
import {Routing} from './Routing';

//
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={Routing}/>
  </React.StrictMode>
);

