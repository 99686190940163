import { BrowserRouter as Router, Route, Link, Switch, Redirect, createBrowserRouter } from 'react-router-dom';

// Import routes here
import LandingPage from "./pages/LandingPage";
import NotFoundPage from "./pages/ErrorPage";

const Routing = createBrowserRouter(
  [
    { 
      path: '/',
      element: <LandingPage />,
      errorElement: <NotFoundPage />,
      children: [
        {
          path: 'home',
          element: <LandingPage />
        }
      ]
    }
  ]
);


export {Routing};
