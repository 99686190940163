import { useState } from "react";

function NavBar(
  currentImageIndex,
  currentDirection,
  setCurrentDirection,
  setShowViewport
) {
  const [statusNav, setStatusNav] = useState(false);

  const goToHome = () => {
    setCurrentDirection("home");
    setShowViewport(false);
    setStatusNav(!statusNav);
    window.history.pushState("", "", "?direction=home");
  };

  const goToAbout = () => {
    setCurrentDirection("about");
    setShowViewport(true);
    setStatusNav(!statusNav);
    window.history.pushState("", "", "?direction=about");
  };

  const goToAreas = () => {
    setCurrentDirection("areas");
    setShowViewport(true);
    setStatusNav(!statusNav);
    window.history.pushState("", "", "?direction=areas");
  };

  const goToContact = () => {
    setCurrentDirection("contact");
    setShowViewport(true);
    setStatusNav(!statusNav);
    window.history.pushState("", "", "?direction=contact");
  };

  const showNav = () => {
    setStatusNav(!statusNav);
    if (currentDirection !== "home") {
      setShowViewport(statusNav);
    } else if (statusNav) {
      setShowViewport(false);
    }
  };

  return (
    <nav id="side-bar" className={statusNav ? "active" : ""}>
      <div className="side-bar-wrapper">
        <header className="logo-wrapper">
          <img
            src={process.env.PUBLIC_URL + "/logo.png"}
            className="App-logo"
            alt="logo"
            style={{
              filter: `invert(${currentImageIndex})`,
            }}
          />
        </header>
        <div className="side-bar-wrapper-links">
          <button
            id="btn-mobile"
            aria-label="Abrir menu"
            aria-haspopup="true"
            aria-controls="menu"
            aria-expanded="false"
            style={{
              filter: `invert(${currentImageIndex})`,
            }}
            onClick={showNav}
          >
            <span id="hamburger"></span>
          </button>
          <ul className="nav">
            <li
              className={`link ${currentDirection === "home" ? "active" : ""}`}
              data-section="home"
              id="0"
            >
              <p onClick={goToHome}>Home</p>
            </li>
            <li
              className={`link ${currentDirection === "about" ? "active" : ""}`}
              data-section="sobre"
              id="1"
            >
              <p onClick={goToAbout}>
                <span className="lang-pt">O escritório</span>{" "}
              </p>
            </li>
            <li
              className={`link ${currentDirection === "areas" ? "active" : ""}`}
              data-section="areas-atuacao"
              id="2"
            >
              <p onClick={goToAreas}>
                <span className="lang-pt">Áreas de atuação</span>
              </p>
            </li>
            <li
              className={`link ${
                currentDirection === "contact" ? "active" : ""
              }`}
              data-section="contato"
              id="5"
            >
              <p onClick={goToContact}>
                <span className="lang-pt">Contato</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
