import { useState, useEffect } from "react";

function About() {
  return (
    <section id="sobre" className="pt">
      <div className="container-inner">
        <div className="titulo-wrapper">
          <h2 className="title lang-pt">Quem somos</h2>
          <h2 className="title lang-en">About Us</h2>
        </div>
        <div className="section-content">
          <div className="sobre">
            <h2 className="-title lang-en">
              Our goal is to find solutions for the challenges of today's world,
              in all our practices.
            </h2>
            
            <h2 className="-title lang-pt">
            Compliance | Direito Penal Empresarial | Payments, Banking, Fintech & Crypto
            </h2>
            <p className="-descricao lang-pt">
Somos um escritório de advocacia especializado em áreas essenciais para o mundo corporativo contemporâneo: Compliance, Direito Penal Empresarial e Direito Regulatório para o Mercado Financeiro.
<br />
<br />
Nosso compromisso é oferecer soluções legais robustas e estratégias inovadoras para garantir a conformidade e a proteção jurídica de empresas, seus executivos e colaboradores em um ambiente cada vez mais complexo e regulamentado.
<br />
<br />
Reconhecemos que cada empresa, independentemente de seu setor, é única e enfrenta desafios específicos. Por isso, desenvolvemos estratégias personalizadas para abordar questões jurídicas complexas, focando na minimização de riscos e na proteção dos interesses de nossos clientes. 
<br />
<br />
Atuamos assessorando departamentos jurídicos de empresas em setores tradicionais, como o mercado financeiro, transportes, construção civil e saúde, bem como empresas que lideram a evolução tecnológica, incluindo Payments, Fintech, Banking, Blockchain & Cryptocurrencies.
<br />
<br />
Se você busca uma equipe jurídica que entenda a complexidade do ambiente empresarial, entre em contato conosco.</p>
            <p className="-descricao lang-en">
              We are a law firm with a multidisciplinary team of professionals
              experienced in the day-to-day work of large national and
              multinational companies, and with extensive academic experience in
              Brazil and abroad. We represent clients from different industries
              of the new economy and of more traditional areas, such as
              fintechs, marketplaces, startups, banks, and construction
              companies. A team that works in an integrated way is the secret to
              our innovative solutions. We believe that being up to date with
              the best legal techniques, as well as the cultural and
              technological advances of society, is the most intelligent and
              comprehensive way to make our clients' businesses feasible. <br />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
