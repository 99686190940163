import { useState, useEffect } from "react";

import Areas from "./LandingContent/Areas";
import About from "./LandingContent/About";
import Contact from "./LandingContent/Contact";

import "./style/Viewport.scss";
import "./style/Language.scss";

function LoadContent(currentDirection) {
  if (currentDirection === "home") {
  } else if (currentDirection === "about") {
    return <About />;
  } else if (currentDirection === "areas") {
    return <Areas />;
  } else if (currentDirection === "contact") {
    return <Contact />;
  }
}

function Viewport(showViewport, currentDirection) {
  return (
    <div id="viewport" className={`${showViewport ? "active" : ""}`}>
      {LoadContent(currentDirection)}
    </div>
  );
}

export default Viewport;
